import React from 'react'
import { MDBRow, MDBCol } from 'mdbreact'
import { GoogleMap, LoadScript } from '@react-google-maps/api'
import { Marker } from '@react-google-maps/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt } from '@fortawesome/pro-solid-svg-icons'

class ColumnAlternatingOffices extends React.Component {
  render() {
    return (
      <MDBRow
        className={`mx-1 alt-area ${
          this.props.placement === 'right' ? 'flex-row-reverse' : ''
        }`}
      >
        <MDBCol lg="6" className="alt-right">
          <div
            id="map-container"
            className="rounded z-depth-1-half map-container"
            style={{ height: '500px' }}
          >
            <LoadScript
              id="script-loader"
              googleMapsApiKey="AIzaSyCpXjW8NbD3JZOylLfE3vYEBj1spgmovhU"
            >
              <GoogleMap
                id="example-map"
                mapContainerStyle={{
                  height: '500px',
                  width: '100%',
                }}
                zoom={15}
                center={{
                  lat: parseFloat(this.props.lat),
                  lng: parseFloat(this.props.lng),
                }}
              >
                <Marker
                  onLoad={marker => {}}
                  position={{
                    lat: parseFloat(this.props.lat),
                    lng: parseFloat(this.props.lng),
                  }}
                />
              </GoogleMap>
            </LoadScript>
          </div>
        </MDBCol>

        <MDBCol lg="6" className="alt-left bg-color">
          <div className="alt-content">
            <h3 className="font-alt font-w-700 letter-spacing-1 mb-5 title-xs-medium title-medium">
              {this.props.office}
            </h3>
            <div
              className="mt-3 mb-5 font-w-400 text-medium"
              style={{ whiteSpace: 'pre-wrap' }}
              dangerouslySetInnerHTML={{ __html: this.props.address }}
            />

            <p className="font-w-400 text-medium mb-0">
              <FontAwesomeIcon
                icon={faMapMarkerAlt}
                className="mr-3 ico-color"
              />
              <a
                className="effect"
                rel="noopener"
                href={
                  'https://www.google.com/maps/dir/Current+Location/' +
                  this.props.lat +
                  ',' +
                  this.props.lng
                }
                target="_blank"
              >
                Directions to this office
              </a>
            </p>
          </div>
        </MDBCol>
      </MDBRow>
    )
  }
}

export default ColumnAlternatingOffices
