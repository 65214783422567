import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBSmoothScroll, toast, ToastContainer } from 'mdbreact'
import { Zoom } from 'react-toastify'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarkerAlt, faPhoneAlt } from '@fortawesome/pro-solid-svg-icons'

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Name must be 2 characters at minimum')
    .required('Name is required'),
  email: Yup.string()
    .email('Invalid email address format')
    .required('Email is required'),
  country: Yup.string()
    .min(2, 'Name must be 2 characters at minimum')
    .required('Country is required'),
  message: Yup.string()
    .min(5, 'Message must be 5 characters at minimum')
    .required('Message is required'),
})

//const formUrl = 'https://script.google.com/macros/s/AKfycby1ZjnbwMuRo-GoRdjZHsczAIhvdbxWktziihuebR0v_9j6qysus7Wk/exec'
const url = 'https://script.google.com/macros/s/AKfycbww7glFGHSgpGqKk0S0pOFZwk5ORnqYqhuiJK7Lx8YDmRCPQjuwBBpY5oPL5L3D_TE33w/exec'

const messageConfirm = () => {
  return toast.info("Thank you for contacting us.  We'll be in touch shortly!", { closeButton: false, position: toast.POSITION.TOP_CENTER, transition: Zoom })
}

class FormMap extends Component {
  constructor(props, ...args) {
    super(props, ...args)
    this.state = {
      callback: 'not fired',
      value: null,
      pageUrl: null,
      load: false,
      expired: 'false',
    }
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ 
        load: true,
        pageUrl: location.href 
      })
    })
  }
 
  asyncScriptOnLoad = () => {
    this.setState({ callback: 'called!' })
  }

  render() {
    const { value, callback, load, expired } = this.state || {}

    return (
      <MDBContainer>
        <MDBRow>
          <MDBCol lg="8" className="pr-lg-5 order-2 order-lg-1">
          <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large">Contact us</h2>
          <p className="mt-3 mb-5 font-w-400 text-medium">We're here to help out with any questions you might have, fill out this short form and a member of our team will get back to you.</p>
            <Formik
              initialValues={{
                name: '',
                email: '',
                country: '',
                message: '', 
                subscribe: false,
              }}
              validationSchema={validationSchema}
              onSubmit={(values, {setSubmitting, resetForm}) => {
                /* alert('Form is validated! Submitting the form...') */
                let formData = {
                  PageUrl: this.state.pageUrl,
                  Name: values.name,
                  Email: values.email,
                  Country: values.country,
                  Message: values.message,
                  Subscribe: values.subscribe,
                }

                try {
                  const response = axios({
                    method: 'get',
                    mode: 'no-cors', 
                    url: `${url}?timestamp=&url=${encodeURIComponent(this.state.pageUrl)}&name=${encodeURIComponent(values.name)}&email=${encodeURIComponent(values.email)}&country=${encodeURIComponent(values.country)}&message=${encodeURIComponent(values.message)}&subscribe=${encodeURIComponent(values.subscribe)}`
                  })
                    console.log(response)
                    this.setState({values: '', expired: 'true'})
                    resetForm()
                    messageConfirm()
                    //alert("Submitted.  Thank you!")
                  } catch (e) {
                    console.log(`Axios request failed: ${e}`)
                  }
                  setSubmitting(false)
                }}
            >
              {({ touched, errors, values, isSubmitting }) => (
                <Form
                  name="contact"
                  method="post"
                  action=""
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="bot-field" />
                  <input type="hidden" name="form-name" value="contact" />
                  <MDBRow>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="name">
                          Name <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="name"
                          name="name"
                          value={values.name}
                          placeholder="Your full name"
                          className={`form-control ${
                            touched.name && errors.name ? 'is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="name"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="email">
                          Email <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="email"
                          name="email"
                          value={values.email}
                          placeholder="Your email address"
                          className={`form-control ${
                            touched.email && errors.email ? 'is-invalid' : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="email"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="6">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="country">
                          Country <span style={{ color: 'red' }}>*</span>
                        </label>
                        <Field
                          type="text"
                          name="country"
                          value={values.country}
                          placeholder="Your country of residence"
                          className={`form-control ${
                            touched.country && errors.country
                              ? 'is-invalid'
                              : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="country"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-600 text-medium">
                        <label htmlFor="message">Message</label>
                        <Field
                          type="textarea"
                          component="textarea"
                          name="message"
                          value={values.message}
                          placeholder="Enter message"
                          className={`form-control ${
                            touched.message && errors.message
                              ? 'is-invalid'
                              : ''
                          }`}
                        />
                        <ErrorMessage
                          component="div"
                          name="message"
                          className="invalid-feedback"
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mt-3 mb-4 grey-text font-w-400 text-small">
                        To receive occasional updates about UNICOM Systems Inc's
                        products, services, news and events, please tick the box
                        below. You can unsubscribe at any time.
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="form-group form-check mt-3 mb-4 grey-text font-w-400 text-small">
                        <Field
                          type="checkbox"
                          id="subscribe"
                          name="subscribe"
                          className={`form-check-input ${
                            touched.subscribe && errors.subscribe
                              ? 'is-invalid'
                              : ''
                          }`}
                        />
                        <label htmlFor="subscribe">
                          Yes, I would like to receive communications from
                          UNICOM Systems, Inc.
                        </label>
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12">
                      <div className="mb-4 grey-text font-w-400 text-small">
                        For details on how your data is stored and used, please
                        visit our{' '}
                        <Link to="/privacy-statement/" className="effect">
                          Privacy Statement
                        </Link>
                        .
                      </div>
                    </MDBCol>
                  </MDBRow>

                  <MDBRow>
                    <MDBCol md="12" className="mb-4">
                      <div style={{ paddingTop: '1rem' }}>
                        <button
                          type="submit"
                          className="mt-3 nav-link btn-sm-block btn btn-mdb-color"
                          style={{ display: 'block' }}
                          disabled={ isSubmitting ? true : false }
                        >
                          {isSubmitting ? 'Please wait...' : 'Submit'}
                        </button>
                        <ToastContainer
                          transition={Zoom}
                          hideProgressBar={true}
                          newestOnTop={true}
                          autoClose={5000}
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                </Form>
              )}
            </Formik>
          </MDBCol>

          <MDBCol lg="4" className="order-1 order-lg-2 mb-5">
            <div className="contact-address">
              <h3 className="font-alt text-center font-w-700 letter-spacing-1 my-2 title-xs-medium title-large">
                Quick links
              </h3>
              <hr className="my-4" />
              <ul className="fa-ul font-w-700 text-large my-5">

                <li>
                  <span className="fa-li">
                    <FontAwesomeIcon
                      icon={faPhoneAlt}
                      size="2x"
                      className="mr-5 ico-color va-m"
                    />
                  </span>
                  <MDBSmoothScroll
                    to="departments"
                    offset={-40}
                    className="text-capitalize text-medium pt-0"
                  >
                    Department details
                    <p className="text-gray-dark font-alt font-w-400 text-medium letter-spacing-1">
                      Our contacts
                    </p>
                  </MDBSmoothScroll>
                </li>

                <li className="pb-3">
                  <span className="fa-li">
                    <FontAwesomeIcon
                      icon={faMapMarkerAlt}
                      size="2x"
                      className="mr-5 ico-color va-m"
                    />
                  </span>
                  <MDBSmoothScroll
                    to="locations"
                    offset={-40}
                    className="text-capitalize text-medium pt-0"
                  >
                    Office location
                    <p className="text-gray-dark font-alt font-w-400 text-medium letter-spacing-1">
                      Where are we?
                    </p>
                  </MDBSmoothScroll>
                </li>

              </ul>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }
}

export default FormMap
